import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import Rewards from './Pages/Rewards';
import Statistics from './Pages/Statistics';
import Profile from './Pages/Profile';
import More from './Pages/More';
import Setting from './Pages/Setting';
import Create from './Pages/Create';
import Wallet from './Pages/Wallet';
function App() {
  return (
    <BrowserRouter>
    <Routes>
         <Route excat path='/' element={<Home/>} />
         <Route  path='/rewards' element={<Rewards />} />
         <Route path='/statistics' element={<Statistics/>}/>
         <Route  path='/profile' element={<Profile/>}/>
         {/* <Route path='/qr-code' element={<Qrcode/>}/> */}
         <Route  path='/more' element={<More/>}/>
         <Route  path='/Create' element={<Create/>}/>
         {/* <Route  path='/Login' element={<Login/>}/> */}
         {/* <Route path='Register'  element={<Register/>}/> */}
         {/* <Route path='Verifyaccount' element={<Verifyaccount/>}/> */}
         <Route path='/Wallet' element={<Wallet/>}/>
         <Route path='/Setting' element={<Setting/>}/>
         
    </Routes>

</BrowserRouter>
  );
}

export default App;
