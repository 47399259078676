import React from 'react'

function Wallet() {
  return (
    <>
    
    <div className="header fixed-top">
      
      <h3>My Wallet</h3>
     
    </div>
    <div className="overflow-auto app-content style-1">
      <div className="tf-container">
        <div className="pb-24 line d-flex flex-column align-items-center box-wallet">
          <div className="avatar round avt-110">
            <img
              className="lazyload"
              data-src="assets/images/avt/avt-wallet.png"
              alt="img"
            />
          </div>
          <div className="mt-20 box-content text-center">
            <h4>Beacon_NFT</h4>
            <span className="button-2 mt-12 d-block ">$410,885.95</span>
          </div>
          <div className="value-field-wallet mt-16">
            <img src="assets/images/avt/metamask.png" alt="metamask" />
            <input type="text" defaultValue="7EK933L1...C93676P7Q4" />
            <svg
              width={20}
              height={20}
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.1665 11.1673V13.6673C14.1665 17.0007 12.8332 18.334 9.49984 18.334H6.33317C2.99984 18.334 1.6665 17.0007 1.6665 13.6673V10.5007C1.6665 7.16732 2.99984 5.83398 6.33317 5.83398H8.83317"
                stroke="#1A1528"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14.1663 11.1673H11.4997C9.49967 11.1673 8.83301 10.5007 8.83301 8.50065V5.83398L14.1663 11.1673Z"
                stroke="#1A1528"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9.6665 1.66602H12.9998"
                stroke="#1A1528"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.8335 4.16602C5.8335 2.78268 6.95016 1.66602 8.3335 1.66602H10.5168"
                stroke="#1A1528"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M18.3339 6.66602V11.8243C18.3339 13.116 17.2839 14.166 15.9922 14.166"
                stroke="#1A1528"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M18.3335 6.66602H15.8335C13.9585 6.66602 13.3335 6.04102 13.3335 4.16602V1.66602L18.3335 6.66602Z"
                stroke="#1A1528"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
        <ul className="py-20 px-16 box-service line">
          <li>
            <a href="#" className="item">
              <div className="box-icon w-48 round">
                <span className="icon icon-import" />
              </div>
              <span className="fw-6 text-dark">Receive</span>
            </a>
          </li>
          <li>
            <a href="#" className="item">
              <div className="box-icon w-48 round">
                <span className="icon icon-bag-2" />
              </div>
              <span className="fw-6 text-dark">Buy</span>
            </a>
          </li>
          <li>
            <a href="#" className="item">
              <div className="box-icon w-48 round">
                <span className="icon icon-send-2" />
              </div>
              <span className="fw-6 text-dark">Send</span>
            </a>
          </li>
          <li>
            <a href="#" className="item">
              <div className="box-icon w-48 round">
                <span className="icon icon-arrow-swap" />
              </div>
              <span className="fw-6 text-dark">Swap</span>
            </a>
          </li>
        </ul>
        <div className="mt-24">
          <div className="tab-slide wrapper-tab">
            <ul className="nav nav-tabs tab-1" role="tablist">
              <li className="item-slide-effect" />
              <li className="nav-item active" role="presentation">
                <button
                  className="nav-link active"
                  data-bs-toggle="tab"
                  data-bs-target="#tokens"
                >
                  Tokens
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  data-bs-toggle="tab"
                  data-bs-target="#NFTs"
                >
                  NFTs
                </button>
              </li>
            </ul>
          </div>
          <div className="tab-content mt-24">
            <div
              className="tab-pane fade active show"
              id="tokens"
              role="tabpanel"
            >
              <ul className="list-view check-list">
                <li className="item">
                  <a href="nft-item-details.html" className="gap-12">
                    <div className="image">
                      <img src="assets/images/wallet/wallet-10.png" alt="wallet" />
                    </div>
                    <div className="content">
                      <div className="body-1">324.67539 ETH</div>
                      <p className="body-4 mt-4">$410,750.45</p>
                    </div>
                    <svg
                      width={20}
                      height={20}
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g opacity="0.6">
                        <path
                          d="M7.4248 16.6004L12.8581 11.1671C13.4998 10.5254 13.4998 9.47539 12.8581 8.83372L7.4248 3.40039"
                          stroke="#1A1528"
                          strokeWidth="1.5"
                          strokeMiterlimit={10}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                    </svg>
                  </a>
                </li>
                <li className="item">
                  <a href="nft-item-details.html" className="gap-12">
                    <div className="image">
                      <img src="assets/images/wallet/wallet-12.png" alt="wallet" />
                    </div>
                    <div className="content">
                      <div className="body-1">36.348508 USDC</div>
                      <p className="body-4 mt-4">$36.4212</p>
                    </div>
                    <svg
                      width={20}
                      height={20}
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g opacity="0.6">
                        <path
                          d="M7.4248 16.6004L12.8581 11.1671C13.4998 10.5254 13.4998 9.47539 12.8581 8.83372L7.4248 3.40039"
                          stroke="#1A1528"
                          strokeWidth="1.5"
                          strokeMiterlimit={10}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                    </svg>
                  </a>
                </li>
                <li className="item">
                  <a href="nft-item-details.html" className="gap-12">
                    <div className="image">
                      <img src="assets/images/wallet/wallet-11.png" alt="wallet" />
                    </div>
                    <div className="content">
                      <div className="body-1">58.487037 USDT</div>
                      <p className="body-4 mt-4">$36.4212</p>
                    </div>
                    <svg
                      width={20}
                      height={20}
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g opacity="0.6">
                        <path
                          d="M7.4248 16.6004L12.8581 11.1671C13.4998 10.5254 13.4998 9.47539 12.8581 8.83372L7.4248 3.40039"
                          stroke="#1A1528"
                          strokeWidth="1.5"
                          strokeMiterlimit={10}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
            <div className="tab-pane fade" id="NFTs" role="tabpanel">
              <ul className="list-view check-list">
                <li className="item">
                  <a href="nft-item-details.html" className="gap-12">
                    <div className="image">
                      <img src="assets/images/wallet/wallet-10.png" alt="wallet" />
                    </div>
                    <div className="content">
                      <div className="body-1">324.67539 ETH</div>
                      <p className="body-4 mt-4">$410,750.45</p>
                    </div>
                    <svg
                      width={20}
                      height={20}
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g opacity="0.6">
                        <path
                          d="M7.4248 16.6004L12.8581 11.1671C13.4998 10.5254 13.4998 9.47539 12.8581 8.83372L7.4248 3.40039"
                          stroke="#1A1528"
                          strokeWidth="1.5"
                          strokeMiterlimit={10}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                    </svg>
                  </a>
                </li>
                <li className="item">
                  <a href="nft-item-details.html" className="gap-12">
                    <div className="image">
                      <img src="assets/images/wallet/wallet-12.png" alt="wallet" />
                    </div>
                    <div className="content">
                      <div className="body-1">36.348508 USDC</div>
                      <p className="body-4 mt-4">$36.4212</p>
                    </div>
                    <svg
                      width={20}
                      height={20}
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g opacity="0.6">
                        <path
                          d="M7.4248 16.6004L12.8581 11.1671C13.4998 10.5254 13.4998 9.47539 12.8581 8.83372L7.4248 3.40039"
                          stroke="#1A1528"
                          strokeWidth="1.5"
                          strokeMiterlimit={10}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                    </svg>
                  </a>
                </li>
                <li className="item">
                  <a href="nft-item-details.html" className="gap-12">
                    <div className="image">
                      <img src="assets/images/wallet/wallet-11.png" alt="wallet" />
                    </div>
                    <div className="content">
                      <div className="body-1">58.487037 USDT</div>
                      <p className="body-4 mt-4">$36.4212</p>
                    </div>
                    <svg
                      width={20}
                      height={20}
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g opacity="0.6">
                        <path
                          d="M7.4248 16.6004L12.8581 11.1671C13.4998 10.5254 13.4998 9.47539 12.8581 8.83372L7.4248 3.40039"
                          stroke="#1A1528"
                          strokeWidth="1.5"
                          strokeMiterlimit={10}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="menubar-footer footer-fixed">
        <ul className="inner-bar">
          
        <li>
         <a href="/">
           <svg
             width={24}
             height={24}
             viewBox="0 0 24 24"
             fill="none"
             xmlns="http://www.w3.org/2000/svg"
           >
             <path
               d="M9.02 2.84016L3.63 7.04016C2.73 7.74016 2 9.23016 2 10.3602V17.7702C2 20.0902 3.89 21.9902 6.21 21.9902H17.79C20.11 21.9902 22 20.0902 22 17.7802V10.5002C22 9.29016 21.19 7.74016 20.2 7.05016L14.02 2.72016C12.62 1.74016 10.37 1.79016 9.02 2.84016Z"
               stroke="#1A1528"
               strokeWidth="1.5"
               strokeLinecap="round"
               strokeLinejoin="round"
             />
             <path
               d="M12 17.9902V14.9902"
               stroke="#1A1528"
               strokeWidth="1.5"
               strokeLinecap="round"
               strokeLinejoin="round"
             />
           </svg>
         </a>
       </li>
          
          <li>
            <a href="/Statistics">
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g opacity="0.6">
                  <path
                    d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                    stroke="#1A1528"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M7.33008 14.4898L9.71008 11.3998C10.0501 10.9598 10.6801 10.8798 11.1201 11.2198L12.9501 12.6598C13.3901 12.9998 14.0201 12.9198 14.3601 12.4898L16.6701 9.50977"
                    stroke="#1A1528"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
              </svg>
            </a>
          </li>
          <li className="action-add-wallet">
            <a href="/wallet">
              {/* <i className="icon-plus" /> */}
              {/* <i className="bi bi-wallet2"></i> */}
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-wallet2" viewBox="0 0 16 16">
                <path d="M12.136.326A1.5 1.5 0 0 1 14 1.78V3h.5A1.5 1.5 0 0 1 16 4.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 13.5v-9a1.5 1.5 0 0 1 1.432-1.499zM5.562 3H13V1.78a.5.5 0 0 0-.621-.484zM1.5 4a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5z" />
              </svg>
              {/* <img src="assets/images/wallet/wallet1.png"  alt="wallet image" /> */}

            </a>
          </li>
          <li>
            <a href="/profile">
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g opacity="0.6">
                  <path
                    d="M12.1596 10.87C12.0596 10.86 11.9396 10.86 11.8296 10.87C9.44957 10.79 7.55957 8.84 7.55957 6.44C7.55957 3.99 9.53957 2 11.9996 2C14.4496 2 16.4396 3.99 16.4396 6.44C16.4296 8.84 14.5396 10.79 12.1596 10.87Z"
                    stroke="#1A1528"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M7.15973 14.56C4.73973 16.18 4.73973 18.82 7.15973 20.43C9.90973 22.27 14.4197 22.27 17.1697 20.43C19.5897 18.81 19.5897 16.17 17.1697 14.56C14.4297 12.73 9.91973 12.73 7.15973 14.56Z"
                    stroke="#1A1528"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
              </svg>
            </a>
          </li>
          <li>
            <a href="/more">
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g opacity="0.6">
                  <path
                    d="M5 10H7C9 10 10 9 10 7V5C10 3 9 2 7 2H5C3 2 2 3 2 5V7C2 9 3 10 5 10Z"
                    stroke="#1A1528"
                    strokeWidth="1.5"
                    strokeMiterlimit={10}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M17 10H19C21 10 22 9 22 7V5C22 3 21 2 19 2H17C15 2 14 3 14 5V7C14 9 15 10 17 10Z"
                    stroke="#1A1528"
                    strokeWidth="1.5"
                    strokeMiterlimit={10}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M17 22H19C21 22 22 21 22 19V17C22 15 21 14 19 14H17C15 14 14 15 14 17V19C14 21 15 22 17 22Z"
                    stroke="#1A1528"
                    strokeWidth="1.5"
                    strokeMiterlimit={10}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5 22H7C9 22 10 21 10 19V17C10 15 9 14 7 14H5C3 14 2 15 2 17V19C2 21 3 22 5 22Z"
                    stroke="#1A1528"
                    strokeWidth="1.5"
                    strokeMiterlimit={10}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
              </svg>
            </a>
          </li>
        </ul>
      </div>
  
    </>
  )
}

export default Wallet